import React, { useEffect, useState, useContext } from "react";
import { message } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import Service from "./../../extra/getAPI";
import Danhsachdonhang from "./danh-sach-don-hang";
import Thongtindonhang from "./thong-tin-don-hang";
import IDothoanthanh from "./../../assets/images/salesManager/dothoanthanh.png";
import IDotCho from "./../../assets/images/salesManager/dotcho.png";
import IChuathanhtoan from "./../../assets/images/salesManager/dotngung.png";
import Ihuy from "./../../assets/images/salesManager/dothuy.png";
import Ichothamdinh from "./../../assets/images/salesManager/ichothamdinh.png";
import { AuthContext } from "./../../context/AuthProvider";
export const GlobalState = React.createContext();

function IndexDonHang() {
  const { setNamePage, indexPage, setIndexPage} = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [product, setProduct] = useState([]);
  const [productsByProvider, setProductsByProvider] = useState([]);
  const [detail, setDetail] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    setNamePage("Quản lý đơn");
    getProducts();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [indexPage === 1]);

  const renderStatus = (status, detail) => {
    switch (status) {
      case 2:
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#069D27",
              fontSize: detail ? 11 : "",
            }}
          >
            <img
              src={IDothoanthanh}
              width={detail ? 7 : 10}
              style={{ marginRight: 10 }}
            />
            <div>Đã thanh toán</div>
          </div>
        );
      case 3:
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#069D27",
              fontSize: detail ? 11 : "",
            }}
          >
            <img
              src={IDothoanthanh}
              width={detail ? 7 : 10}
              style={{ marginRight: 10 }}
            />
            <div>Hoàn thành</div>
          </div>
        );
      case 10:
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#FFA21F",
              fontSize: detail ? 11 : "",
            }}
          >
            <img
              src={IDotCho}
              width={detail ? 7 : 10}
              style={{ marginRight: 10 }}
            />
            <div>Chờ GCN</div>
          </div>
        );
      case 4:
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#FF003D",
              fontSize: detail ? 11 : "",
            }}
          >
            <img
              src={Ihuy}
              width={detail ? 7 : 10}
              style={{ marginRight: 10 }}
            />
            <div>Đơn huỷ</div>
          </div>
        );
        case 11:
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#FF003D",
              fontSize: detail ? 11 : "",
            }}
          >
            <img
              src={Ihuy}
              width={detail ? 7 : 10}
              style={{ marginRight: 10 }}
            />
            <div>Từ chối</div>
          </div>
        );
      case 5:
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#FF003D",
              fontSize: detail ? 11 : "",
            }}
          >
            <img
              src={Ihuy}
              width={detail ? 7 : 10}
              style={{ marginRight: 10 }}
            />
            <div>Lỗi thanh toán</div>
          </div>
        );
      case 7:
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#FF003D",
              fontSize: detail ? 11 : "",
            }}
          >
            <img
              src={Ihuy}
              width={detail ? 7 : 10}
              style={{ marginRight: 10 }}
            />
            <div>Lỗi cấp GCN</div>
          </div>
        );
      case 8:
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#0B7CE4",
              fontSize: detail ? 11 : "",
            }}
          >
            <img
              src={Ichothamdinh}
              width={detail ? 7 : 10}
              style={{ marginRight: 10 }}
            />
            <div>Chờ thẩm định</div>
          </div>
        );
      case 1:
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#4b4b4b",
              fontSize: detail ? 11 : "",
            }}
          >
            <img
              src={IChuathanhtoan}
              width={detail ? 7 : 10}
              style={{ marginRight: 10 }}
            />
            <div>Chưa thanh toán</div>
          </div>
        );
      default:
        break;
    }
  };

  // Lấy danh sách sản phẩm
  const getProducts = () => {
    Service.apicall("GET", `/api/v1/discount/products`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setProduct(res.data.result);
        } else {
          setProduct([]);
        }
      })
      .catch((err) => {
        setProduct([]);
      });
  };

  // Lấy danh sách sản phẩm
  const getProductsByProvider = (productTypeId) => {
    Service.apicall(
      "GET",
      `/api/v1/discount/products/${productTypeId}/product-by-provider`
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setProductsByProvider(res.data.result);
        } else {
          setProductsByProvider([]);
        }
      })
      .catch((err) => {
        setProductsByProvider([]);
      });
  };

  const PageView = () => {
    setIndexPage(0);
    switch (page) {
      case 1:
        return <Danhsachdonhang />;
      case 2:
        return <Thongtindonhang />;
    }
  };

  return (
    <GlobalState.Provider
      value={{
        setPage,
        page,
        renderStatus,
        product,
        getProductsByProvider,
        productsByProvider,
        detail,
        setDetail,
      }}
    >
      <div>{PageView()}</div>
    </GlobalState.Provider>
  );
}

export default IndexDonHang;
