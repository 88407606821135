import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Select,
  DatePicker,
  InputNumber,
  Radio,
  Checkbox,
  message,
  TreeSelect,
  Tooltip
} from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import Service from "./../../extra/getAPI";
import IXoa from "./../../assets/images/salesManager/trash-02.png";
import Itaogoi from "./../../assets/images/salesManager/itaogoi2.png";
import Imota from "./../../assets/images/salesManager/imota.png";
import Helper from "./../../extra/helper";
import MDConfirmCreate from "./../../components/modal/khuyen-mai/mdConfirmCreate";
import ISub from "./../../assets/images/salesManager/subicon.png";

import { AuthContext } from "./../../context/AuthProvider";
import { GlobalState } from "./index";

export default function Taokhuyenmai() {
  const { age, getAge, province, district, getDistrict, setDistrict } = useContext(AuthContext);
  const {
    Back,
    benefits,
    handleBenefits,
    setBenefits,
    productType,
    getProducts,
    getPackages,
    packages,
    getProductsByProvider,
    productsByProvider,
    getBenefitsDetail,
    benefitsDetail,
    setBenefitsDetail,
    setPackages,
    loadingIndex,
    MessageSucc,
  } = useContext(GlobalState);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const { Option } = Select;
  const { TreeNode } = TreeSelect;
  const [test, settest] = useState(1);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [formData, setFormData] = useState({});
  const [dataCreate, setDataCreate] = useState({});
  const [minFees, setMinFees] = useState(0);
  const [status, setStatus] = useState(1);
  const [loading, setLoading] = useState(false);
  const [checkAge, setCheckAge] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getAge();
    getProducts();
    // setIndexPage(0);
    // form.setFieldsValue({ category: 'chiet_khau' });
  }, []);

  useEffect(() => {
    // console.log("benefits", benefits);
    const dataBenefits = {};
    benefits.forEach((item, index) => {
      dataBenefits[`gender${index}`] = item.gender;
      dataBenefits[`from_age${index}`] =
        +item?.under_1_age === 364 ? +item?.under_1_age : item.from_age;
      dataBenefits[`to_age${index}`] = +item?.under_1_age_extended === 364 ? 364 : item.to_age;
    });
    form.setFieldsValue(dataBenefits);
  }, [benefits]);

  useEffect(() => {
    // console.log("benefitsDetail", benefitsDetail);
    if (benefitsDetail.length > 0) {
      const newDetail = benefitsDetail.map((item) => {
        if (item?.from_age === 364) {
          return { ...item, from_age: 0 };
        }
        return item;
      });
      // Tìm số tuổi nhỏ nhất và lớn nhất trong mảng allAges
      const minAge = Math.min(...newDetail.map((item) => item.from_age));
      const maxAge = Math.max(...newDetail.map((item) => item.to_age));
      setCheckAge({
        minAge: minAge,
        maxAge: maxAge,
      });

      // Hàm để chuyển đổi giá trị chuỗi thành số và kiểm tra hợp lệ
      const toValidNumber = (value) => {
        const num = Number(value);
        return num !== 0 ? num : Infinity;
      };

      // Sử dụng reduce để tìm giá trị nhỏ nhất mà khác 0
      const minValue = benefitsDetail.reduce((acc, item) => {
        const minFees = toValidNumber(item.min_fees);
        const fees = toValidNumber(item.fees);

        // Tìm giá trị nhỏ nhất giữa acc, minFees và fees
        return Math.min(acc, minFees, fees);
      }, Infinity);

      // Kiểm tra và xử lý trường hợp không tìm thấy giá trị hợp lệ
      const result = minValue === Infinity ? null : minValue;
      setMinFees(result);
    }
    // console.log('tuoi', checkAge);
  }, [benefitsDetail]);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        // if (values?.type === "tien" && +minFees < +values?.value) {
        //   message.error("Giá trị khuyến mãi không được cao hơn " + Helper._formatMoney2(minFees) + " VNĐ");
        //   form.resetFields(["value"]);
        //   return;
        // }
        if (!values.packages_uid) {
          message.error("Sản phẩm chưa có gói sản phẩm. Vui lòng kiểm tra lại!");
          return;
        }
        const data = {
          category: values.category,
          product_id: values.product_id,
          begin_date: Helper._formatDayNoHour2(values.begin_date) + " 00:00:00",
          end_date: Helper._formatDayNoHour2(values.end_date) + " 23:59:59",
          type: values.type,
          value: values.value,
          maximum_usage: values.maximum_usage,
          pbp_id: values.pbp_id.substring(values.pbp_id.indexOf("-") + 1),
          packages_uid: values.packages_uid,
          benefits: values?.loai_doi_tuong === 1 ? [] : benefits,
          provinces: values.provinces,
          districts: values?.districts || []
        };
        if (values.type === "phan_tram") {
          data.value_limit = values.value_limit;
        }
        if (status === 5) {
          data.status = 5;
          setOpenConfirm(true);
        } else {
          data.status = 1;
          Create(data);
        }
        setDataCreate(data);
      })
      .catch((err) => {
        // console.error("err:", err);
      });
  };

  // Tạo khuyến mãi
  const Create = (data) => {
    setLoading(true);
    Service.apicall("POST", `/api/v1/discount`, data)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          Back(1);
          handCancel();
          MessageSucc("Tạo khuyến mãi thành công!");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handCancel = () => {
    setOpenConfirm(false);
  };

  const onChangForm = ({ data, name }) => {
    //type,loai_doi_tuong 1 ẩn bảng 2 hiện bảng, gói sản phẩm
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  return (
    <>
      {openConfirm && (
        <MDConfirmCreate
          open={true}
          create={() => Create(dataCreate)}
          handleCancel={handCancel}
          loading={loading}
        />
      )}
      <Form
        layout={"vertical"}
        ref={formRef}
        scrollToFirstError
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row style={{ overflow: "hidden", maxWidth: 900 }}>
          <Col xl={24} xs={24}>
            <div style={{ marginBottom: 10, fontWeight: 600, fontSize: 20 }}>
              Tạo khuyến mãi
            </div>
          </Col>
          <div
            style={{
              background: "#ffffff",
              width: "100%",
              padding: 20,
              borderRadius: 2,
              color: "#4B4B4B",
            }}
          >
            <Row gutter={[60, 0]}>
              <Col xs={24}>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  Thông tin cơ bản
                </div>
              </Col>
              <Col xl={24} xs={24}>
                <Row gutter={[10]}>
                  <Col xl={12} xs={24}>
                    <Form.Item
                      className="username"
                      name="category"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Loại giảm giá</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                    >
                      <Select
                        allowClear={false}
                        onChange={(e) => {
                          if (e === 'khuyen_mai') {
                            setBenefits([{ gender: null }]);
                            setBenefitsDetail([]);
                            form.setFieldsValue({ 'loai_doi_tuong': 1, })
                            setFormData({ ...formData, loai_doi_tuong: 1, category: e });
                          } else {
                            onChangForm({ data: e, name: "category" });
                          }
                        }}
                        className="custom-select2"
                        placeholder={<span>Chọn loại giảm giá</span>}
                      >
                        <Option value={"khuyen_mai"}>Khuyến mãi</Option>
                        <Option value={"chiet_khau"}>Chiết khấu</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Form.Item
                      className="username"
                      name="product_id"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Chọn loại bảo hiểm
                        </span>
                      }
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          if (e) {
                            form.resetFields(["pbp_id", "packages_uid"]);
                            getProductsByProvider(e);
                            setBenefits([]);
                            setBenefitsDetail([]);
                            setPackages([]);
                          }
                        }}
                        allowClear={false}
                        showSearch={false}
                        className="custom-select2"
                        placeholder={<span>Chọn loại bảo hiểm</span>}
                      >
                        {productType.map((item) => (
                          <Option key={item?.id} value={item?.id}>
                            {item?.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xl={12}
                    xs={24}
                    style={{ position: "relative" }}
                  >
                    <Form.Item
                      className="TreeSelectCustom username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Tỉnh thành, thành phố{" "}
                          <Tooltip
                            overlayStyle={{ fontSize: 13 }}
                            mouseEnterDelay={0.5}
                            title={'Chỉ chọn khi khuyến mãi theo từng khu vực'}
                          >
                            <img src={Imota} width={15} style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </span>
                      }
                      name="provinces"
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng nhập thông tin.",
                        },
                      ]}
                    >
                      <TreeSelect
                        maxTagCount={"responsive"}
                        disabled={false}
                        // value={value}
                        dropdownStyle={{ overflow: 'auto', width: '100%' }}
                        placeholder="Chọn tỉnh thành, thành phố"
                        // open={false}
                        multiple
                        treeIcon={false}
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_ALL}
                        treeDefaultExpandAll
                        onChange={(e) => {
                          onChangForm({ data: e, name: "provinces" });
                          form.resetFields(['districts']);
                          setDistrict([]);
                          if (e?.length === 1) {
                            getDistrict(e[0])
                          }
                        }}
                        treeNodeFilterProp="title"
                      >
                        {
                          province.map((item, i) => (
                            <TreeNode key={item?.id} value={item?.id} title={item?.title} />
                          ))
                        }
                      </TreeSelect>
                    </Form.Item>
                    <img
                      src={ISub}
                      style={{
                        position: "absolute",
                        top: 48,
                        right: 13,
                        width: 11,
                        opacity: 0.5,
                      }}
                    />
                  </Col>
                  <Col
                    xl={12}
                    xs={24}
                    style={{ position: "relative" }}
                  >
                    <Form.Item
                      className="TreeSelectCustom username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Quận, huyện
                        </span>
                      }
                      name="districts"
                      rules={[
                        {
                          // required: formData?.provinces?.length === 1?true:false,
                          required: false,
                          message: "Vui lòng nhập thông tin.",
                        },
                      ]}
                    >
                      <TreeSelect
                        maxTagCount={"responsive"}
                        disabled={formData?.provinces?.length > 1 ? true : false}
                        // value={value}
                        dropdownStyle={{ overflow: 'auto', width: '100%' }}
                        placeholder="Chọn quận, huyện"
                        // open={false}
                        multiple
                        treeIcon={false}
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_ALL}
                        treeDefaultExpandAll
                        treeNodeFilterProp="title"
                      >
                        {
                          district.map((item, i) => (
                            <TreeNode key={item?.id} value={item?.id} title={item?.title} />
                          ))
                        }
                      </TreeSelect>
                    </Form.Item>
                    <img
                      src={ISub}
                      style={{
                        position: "absolute",
                        top: 48,
                        right: 13,
                        width: 11,
                        opacity: 0.5,
                      }}
                    />
                  </Col>
                  <Col xl={12} xs={24}>
                    <Form.Item
                      className="username"
                      name="begin_date"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Ngày bắt đầu</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                    >
                      <DatePicker
                        onChange={(e) => { 
                          form.resetFields(["end_date"]) ;
                          onChangForm({
                            data: e,
                            name: "begin_date",
                          });
                        }}
                        placeholder="Ngày bắt đầu"
                        style={{ width: "100%", borderRadius: 6, height: 41 }}
                        format={"DD/MM/YYYY"}
                        disabledDate={(current) =>
                          current && current < moment().startOf("day")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <Form.Item
                      className="username"
                      name="end_date"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Ngày kết thúc</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="Ngày kết thúc"
                        defaultPickerValue={formData?.begin_date ? moment(formData?.begin_date) : null}
                        style={{ width: "100%", borderRadius: 6, height: 41 }}
                        format={"DD/MM/YYYY"}
                        disabledDate={(current) => {
                          let customDate2 = moment(
                            form.getFieldValue("begin_date")
                          )
                            .add(0, "day")
                            .format("YYYY-MM-DD");
                          return (
                            (current &&
                              current < moment(customDate2, "YYYY-MM-DD")) ||
                            (current && current < moment().startOf("day"))
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            style={{
              background: "#ffffff",
              width: "100%",
              padding: 20,
              borderRadius: 2,
              color: "#4B4B4B",
              marginTop: 20,
            }}
          >
            <Row gutter={[60, 0]}>
              <Col xs={24}>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  Thiết lập mã giảm giá
                </div>
              </Col>
              <Col xl={24} xs={24}>
                <Row gutter={[10]}>
                  <Col xl={12} xs={24}>
                    <Form.Item
                      className="username"
                      name="type"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Loại giảm giá</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          onChangForm({ data: e, name: "type" });
                          form.resetFields(["value"]);
                        }}
                        allowClear={false}
                        className="custom-select2"
                        placeholder={<span>Chọn loại giảm giá</span>}
                      >
                        <Option value={"phan_tram"}>Theo phần trăm</Option>
                        <Option value={"tien"}>Theo số tiền</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={12} xs={24}>
                    <div style={{ position: "relative" }}>
                      <Form.Item
                        className="username"
                        name="value"
                        style={{ marginBottom: 10 }}
                        label={
                          <span style={{ fontWeight: 400 }}>Nhập giá trị</span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng nhập thông tin",
                          },
                          {
                            pattern:
                              formData?.type === "phan_tram"
                                ? /^((100)|([1-9][0-9]{1})|([1-9]))$/
                                : /^((1000000000000)|([1-9][0-9]{11})|([1-9][0-9]{10})|([1-9][0-9]{9})|([1-9][0-9]{8})|([1-9][0-9]{7})|([1-9][0-9]{6})|([1-9][0-9]{5})|([1-9][0-9]{4})|([1-9][0-9]{3}))$/,
                            message:
                              formData?.type === "phan_tram"
                                ? "Giá trị không hợp lệ"
                                : "Giá trị không hợp lệ",
                          },
                        ]}
                      >
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                          }
                          parser={(value) => value.replace(/(\.*)/g, "")}
                          // style={css.inputNumber}
                          style={{
                            width: "100%",
                            borderRadius: 6,
                            padding: "4px 0px",
                            position: "relative",
                          }}
                          placeholder={
                            formData?.type === "phan_tram"
                              ? "Nhập giá trị lớn hơn 1%"
                              : "Nhập giá trị lớn hơn 1.000"
                          }
                        />
                      </Form.Item>
                    </div>
                    <div style={css.suffix}>
                      {formData?.type === "phan_tram" ? "%" : "VNĐ"}
                    </div>
                  </Col>
                  {formData?.type === "phan_tram" && (
                    <>
                      <Col xl={12} xs={24}>
                        <Form.Item
                          className="username"
                          name="mucgiam"
                          style={{ marginBottom: 10 }}
                          label={
                            <span style={{ fontWeight: 400 }}>
                              Mức giảm tối đa
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng chọn thông tin",
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              onChangForm({
                                data: e.target.value,
                                name: "mucgiam",
                              });
                              if (e.target.value === 0) {
                                form.setFieldsValue({ value_limit: 0 });
                              } else {
                                form.resetFields(["value_limit"]);
                              }
                            }}
                          >
                            <Radio value={0}>Không giới hạn</Radio>
                            <Radio value={1}>Giới hạn</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col
                        style={{
                          display: formData?.mucgiam === 0 ? "none" : "",
                        }}
                        xl={12}
                        xs={24}
                      >
                        <div style={{ position: "relative" }}>
                          <Form.Item
                            className="username"
                            name="value_limit"
                            style={{ marginBottom: 10, marginTop: 16 }}
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng nhập thông tin",
                              },
                            ]}
                          >
                            <InputNumber
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                              }
                              parser={(value) => value.replace(/(\.*)/g, "")}
                              // style={css.inputNumber}
                              min={formData?.mucgiam === 0 ? 0 : 1000}
                              style={{
                                width: "100%",
                                borderRadius: 6,
                                padding: "4px 0px",
                                position: "relative",
                              }}
                              placeholder="Nhập giới hạn"
                            />
                          </Form.Item>
                        </div>
                        <div style={{ ...css.suffix, top: 20 }}>VNĐ</div>
                      </Col>
                    </>
                  )}

                  <Col xs={24} xl={24}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Tổng lượt sử dụng tối đa
                        </span>
                      }
                      name="maximum_usage"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập thông tin",
                        },
                      ]}
                    >
                      <InputNumber
                        min={1}
                        max={2000000}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }
                        parser={(value) => value.replace(/(\.*)/g, "")}
                        // style={css.inputNumber}
                        style={{
                          width: "100%",
                          borderRadius: 6,
                          padding: "4px 0px",
                          position: "relative",
                        }}
                        placeholder="Nhập số lượt tối đa"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            style={{
              background: "#ffffff",
              width: "100%",
              padding: 20,
              borderRadius: 2,
              color: "#4B4B4B",
              marginTop: 20,
            }}
          >
            <Row gutter={[60, 0]}>
              <Col xs={24}>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  Chọn gói sản phẩm
                </div>
              </Col>
              <Col xl={24} xs={24}>
                <Row gutter={[10]}>
                  <Col xl={24} xs={24}>
                    <Form.Item
                      className="username"
                      name="pbp_id"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Chọn sản phẩm</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          if (e) {
                            getPackages(
                              e.substring(0, e.indexOf("-")),
                              e.substring(e.indexOf("-") + 1)
                            );
                            setBenefits([{ gender: null }]);
                            setBenefitsDetail([]);
                            setPackages([]);
                          }
                        }}
                        allowClear={false}
                        className="custom-select2"
                        placeholder={<span>Chọn sản phẩm</span>}
                      >
                        {productsByProvider?.map((item) => (
                          <Option
                            key={item?.id}
                            value={item.product_id + "-" + item.id}
                          >
                            {item?.product_title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    style={{
                      display:
                        packages?.filter((i) => i?.status === 2)?.length > 0
                          ? ""
                          : "none",
                    }}
                    xs={24}
                    xl={24}
                  >
                    <Form.Item
                      className="username"
                      style={{ margin: 0 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Chọn gói sản phẩm
                        </span>
                      }
                      name="packages_uid"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                    >
                      <Checkbox.Group
                        onChange={() => {
                          if (formData?.category === 'chiet_khau') {
                            setBenefits([{ gender: null }]);
                            form.resetFields(["loai_doi_tuong"]);
                          }
                        }}
                        style={{ width: "100%" }}
                      >
                        <Row>
                          {packages
                            .filter((i) => i?.status === 2)
                            .map((item) => (
                              <Col key={item.uid} xl={6} xs={12}>
                                <Checkbox
                                  disabled={loadingIndex}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      const pbp_id =
                                        form.getFieldValue("pbp_id");
                                      getBenefitsDetail(
                                        pbp_id?.substring(
                                          0,
                                          pbp_id.indexOf("-")
                                        ),
                                        e.target.value
                                      );
                                    } else if (!e.target.checked) {
                                      setBenefits([]);
                                      setBenefitsDetail(
                                        benefitsDetail.filter(
                                          (i) => i.packages_uid !== item.uid
                                        )
                                      );
                                    }
                                  }}
                                  value={item.uid}
                                >
                                  {item?.title}
                                </Checkbox>
                              </Col>
                            ))}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                  <Col
                    style={{
                      display:
                        packages?.filter((i) => i?.status === 2)?.length === 0 && packages?.length > 0
                          ? ""
                          : "none",
                    }}
                    xs={24}
                    xl={24}
                  >
                    <div style={{ color: 'red' }}>Chưa có gói sản phẩm được kích hoạt. Vui lòng kích hoạt để tạo khuyến mãi!</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            style={{
              background: "#ffffff",
              width: "100%",
              padding: 20,
              borderRadius: 2,
              color: "#4B4B4B",
              marginTop: 20,
            }}
          >
            <Row gutter={[0, 0]}>
              <Col xs={24}>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    marginBottom: 10,
                  }}
                >
                  Đối tượng áp dụng mã
                </div>
              </Col>
              <Col xl={24} xs={24}>
                <Row gutter={[0, 0]}>
                  <Col xl={12} xs={24}>
                    <Form.Item
                      className="username"
                      name="loai_doi_tuong"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Loại đối tượng</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                    >
                      <Select
                        disabled={formData?.category === "khuyen_mai" ? true : false}
                        onChange={(e) => {
                          onChangForm({ data: e, name: "loai_doi_tuong" });
                          if (e === 1) {
                            setBenefits([]);
                          } else {
                            setBenefits([{ gender: null }]);
                          }
                        }}
                        allowClear={false}
                        className="custom-select2"
                        placeholder={<span>Phân loại đối tượng</span>}
                      >
                        <Option value={1}>Tất cả đối tượng</Option>
                        <Option value={2}>Phân loại đối tượng</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {formData?.loai_doi_tuong === 2 && (
                    <Col
                      style={{ marginTop: 10, overflowX: "auto" }}
                      xs={24}
                      xl={24}
                    >
                      <table
                        style={{ whiteSpace: "nowrap", minWidth: 860 }}
                        className="table-cuctom"
                      >
                        <tr>
                          <th>STT</th>
                          <th style={{ minWidth: 114 }}>Giới tính</th>
                          <th>Phân loại tuổi</th>
                          <th>Thao tác</th>
                        </tr>
                        {benefits.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <Form.Item
                                className="username"
                                name={"gender" + index}
                                style={{ margin: 0 }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng chọn thông tin",
                                  },
                                ]}
                              >
                                <Select
                                  onChange={(e) =>
                                    handleBenefits("edit", index, {
                                      ...item,
                                      gender: e,
                                    })
                                  }
                                  className="custom-select2"
                                  style={{ textAlign: "left" }}
                                  placeholder={<span>Chọn giới tính</span>}
                                >
                                  <Option value={1}>Nam</Option>
                                  <Option value={2}>Nữ</Option>
                                </Select>
                              </Form.Item>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <span>Từ</span>
                                <Form.Item
                                  className="username"
                                  name={"from_age" + index}
                                  style={{ margin: 0, width: 224 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Vui lòng chọn thông tin",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    allowClear={false}
                                    onChange={(e) =>
                                      handleBenefits("edit", index, {
                                        ...item,
                                        from_age: +e === 364 ? 0 : +e,
                                        under_1_age:
                                          +e === 0
                                            ? 15
                                            : e === 364
                                              ? 364
                                              : null,
                                      })
                                    }
                                    className="custom-select2"
                                    style={{ textAlign: "left" }}
                                    placeholder={<span>Chọn tuổi</span>}
                                  >
                                    {age?.filter(item =>
                                      (item.type === 'year' && item.value >= checkAge.minAge && item.value <= checkAge.maxAge) ||
                                      (item.type === 'day' && (checkAge.minAge === 0 || (item.value / 365 >= checkAge.minAge && item.value / 365 <= checkAge.maxAge)))
                                    )?.map((item) => (
                                      <Option
                                        key={item?.uid}
                                        value={item?.value}
                                        style={{
                                          // display:
                                          //   +item?.value < +checkAge?.minAge
                                          //     ? "none"
                                          //     : "",
                                        }}
                                      >
                                        {item?.title}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <span>Đến</span>
                                <Form.Item
                                  className="username"
                                  name={"to_age" + index}
                                  style={{ margin: 0, width: 224 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Vui lòng chọn thông tin",
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch
                                    allowClear={false}
                                    onChange={(e) => {
                                      handleBenefits("edit", index, {
                                        ...item,
                                        to_age: e === 364 ? 0 : e,
                                        under_1_age_extended: +e === 0
                                          ? 0
                                          : e === 364
                                            ? 364
                                            : null,
                                      })
                                    }
                                    }
                                    className="custom-select2"
                                    style={{ textAlign: "left" }}
                                    placeholder={<span>Chọn tuổi</span>}
                                  >
                                    {/* {age?.map((item, i) => ( */}
                                    {age?.filter(item =>
                                      (item.type === 'year' && item.value >= checkAge.minAge && item.value <= checkAge.maxAge) ||
                                      (item.type === 'day' && (checkAge.minAge === 0 || (item.value / 365 >= checkAge.minAge && item.value / 365 <= checkAge.maxAge)))
                                    )?.map((item, i) => (
                                      <Option
                                        key={item?.uid}
                                        value={item?.value}
                                      >
                                        {item?.title}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </td>
                            <td>
                              <img
                                onClick={() => {
                                  if (benefits.length === 1) {
                                    return;
                                  }
                                  handleBenefits("delete", index);
                                }}
                                src={IXoa}
                                style={{
                                  width: 17,
                                  opacity: benefits.length > 1 ? 1 : 0.5,
                                  cursor: "pointer",
                                }}
                              />
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td style={{ textAlign: "left" }} colSpan={4}>
                            <div
                              onClick={() =>
                                handleBenefits("add", null, {
                                  gender: null,
                                })
                              }
                              style={css.themtuoi}
                            >
                              <img
                                src={Itaogoi}
                                style={{
                                  width: 18,
                                  marginRight: 7,
                                  cursor: "pointer",
                                }}
                              />
                              Thêm độ tuổi
                            </div>
                          </td>
                        </tr>
                      </table>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <Row gutter={[60, 0]}>
              <Col xs={24} xl={24}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 20,
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={() => Back(1)}
                    style={{
                      background: "#ffffff",
                      color: "#0B7CE4",
                      width: "90px",
                      textAlign: "center",
                      borderRadius: 6,
                      border: "1px solid #0B7CE4",
                      height: 40,
                    }}
                  >
                    <b>Bỏ qua</b>
                  </Button>
                  <div>
                    <Button
                      htmlType="submit"
                      loading={loading}
                      onClick={() => setStatus(1)}
                      style={{
                        color: "#0B7CE4",
                        textAlign: "center",
                        borderRadius: 6,
                        border: "1px solid #0B7CE4",
                        height: 40,
                        marginRight: 10,
                      }}
                    >
                      <b>Lưu nháp</b>
                    </Button>
                    <Button
                      htmlType="submit"
                      loading={loading}
                      onClick={() => setStatus(5)}
                      style={{
                        background: "#0B7CE4",
                        color: "#ffffff",
                        textAlign: "center",
                        borderRadius: 6,
                        border: "1px solid #0B7CE4",
                        height: 40,
                      }}
                    >
                      <b>Hiển thị</b>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Form>
    </>
  );
}

const css = {
  suffix: {
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    position: "absolute",
    right: 15,
    top: 37,
    cursor: "pointer",
    fontSize: 14,
    color: "#A0A0A0",
  },
  themtuoi: {
    padding: "7px 11px",
    border: "1px solid #3961A0",
    width: "fit-content",
    color: "#3961A0",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};
